const colorMxn = {
  data() {
    return {
      cardHeader: "#dbd7d779",
      ant: "#273142",
      dark: "#1f2735",
      modalColor: "#54657e",
      background: "#1f2735",
      bids: "#2D3748",
      btnFab: "#737984",
      yellowOrange: "#ff9948",
      essayGreen: '#038c43',
      primaryBlue: '#286bb8',
      essayOrange: '#F15B07',
      essayGrey: '#C4C4C4',
    };
  },
  methods: {
    orderStatusColor(orderStatus) {
      let color = "primary";
      switch(orderStatus) {
        case 'draft':
          color = this.btnFab;
          break;
        case 'open':
          color = 'primary';
          break;
        case 'bidding':
          color = 'primary';
          break;
        case 'in-progress':
          color = 'primary';
          break;
        case 'completed':
          color = this.essayGreen;
          break;
        case 'closed':
          color = 'error';
          break;
        default:
          break;
      }

      return color
    },
    getTransactionColor(status) {
      let color = this.primaryBlue;
      switch(status) {
        case 'pending':
          color = this.info;
          break;
        case 'successful':
          color = this.essayGreen;
          break;
        case 'failed':
          color = 'red';
          break;
        default:
          break;
      }
    return color;
    },
  }
};

export default colorMxn;
